<template>
  <div class="my-1">
    <label
      v-if="props.label"
      class="text-sm mb-1 text-black font-medium"
      v-text="props.label"
    />
    <AvvSelect
      class="avv-input-tw no-radius"
      :placeholder="props.placeholder"
      :value="props.modelValue"
      :search="true"
      @select="selectValue"
    >
      <div class="menu !overflow-y-scroll">
        <div class="relative">
          <input
            v-model="searchModel"
            type="search"
            class="avv-input w-full !m-0 no-search-decoration"
            :placeholder="localize(isOpen ? 'placeholder_open' : 'placeholder')"
            @keydown.enter="addValue"
          />
          <div
            v-if="searchModel.length > 0"
            class="absolute flex right-2 top-[25%]"
          >
            <i
              class="material-icons rotate-45"
              aria-hidden="true"
              :aria-label="localize('clear')"
              :title="localize('clear')"
              @click="clearValue"
              >add</i
            >
            <i
              v-if="isOpen"
              class="material-icons ml-2"
              aria-hidden="true"
              :aria-label="localize('add')"
              :title="localize('add')"
              @click="addValue"
              >add</i
            >
          </div>
        </div>
        <avv-option
          v-for="item in searchValues"
          :key="item"
          :value="item"
          class="relative"
        >
          <span class="text" v-text="displayValue(item)" />
          <i
            v-if="isEdit && isReference && item"
            class="material-icons !absolute right-2 top-2.5 text-xl !pointer-events-auto"
            aria-hidden="true"
            :aria-label="localize('edit')"
            :title="localize('edit')"
            @click="() => editValue(item)"
            >edit</i
          >
        </avv-option>
      </div>
    </AvvSelect>
    <span
      v-if="props.helpText"
      class="text-sm text-gray-500 mt-1"
      v-text="props.helpText"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import {
  dsCellName,
  dsSplit
} from './../../../questionnaire/types/datasheets/utils'
import { uniqueArray } from '@avvoka/shared'

const AvvSelect = window.AvvSelect

const props = withDefaults(
  defineProps<{
    modelValue: string
    values: Array<string>
    label?: string
    helpText?: string
    placeholder?: string
    open?: boolean
    edit?: boolean
    reference?: boolean
  }>(),
  {
    label: '',
    helpText: '',
    placeholder: '',
    open: false,
    edit: false,
    reference: false
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'insert', value: string): void
  (e: 'edit', value: string, id: string): void
}>()

// Modificators
const isOpen = !!props.open
const isEdit = !!props.edit
const isReference = !!props.reference

const localize = (key: string): string =>
  window.localizeText(
    `datasheets.datasheet_record_dialog.datasheet_select.${key}`
  )

const manualValues = ref<string[]>([])
if (props.modelValue) {
  if (isOpen && !isReference) {
    manualValues.value.push(props.modelValue)
  } else if (isReference && props.values.includes(props.modelValue)) {
    // Move selected value to the beginning so it doesnt get consumed
    manualValues.value.push(props.modelValue)
  }
}

const values = computed(() =>
  uniqueArray(props.values.concat(manualValues.value), (item) =>
    dsCellName(item)
  )
)

if (isReference) {
  watch(
    () => props.values,
    (newValues) => {
      if (newValues.length <= 1) {
        emit('update:modelValue', newValues[0] ?? '')
      } else if (
        newValues.some(
          (value) => dsCellName(value) === dsCellName(props.modelValue)
        )
      ) {
        emit(
          'update:modelValue',
          newValues.find(
            (value) => dsCellName(value) === dsCellName(props.modelValue)
          ) ?? ''
        )
      } else {
        emit('update:modelValue', '')
      }
    }
  )
}

// Clean value (convert to lower case and trim)
const clean = (value: string | null): string => {
  return (value ?? '').trim().toLowerCase()
}

// Value filter
const searchModel = ref<string>('')
const searchValues = computed(() => {
  const term = clean(searchModel.value)

  if (term) {
    const filterMethod = isReference
      ? (item: string) => dsCellName(item).toLowerCase().includes(term)
      : (item: string) => item.toLowerCase().includes(term)

    return values.value.filter(filterMethod)
  } else {
    return values.value
  }
})

const selectValue = (option: HTMLOptionElement) => {
  emit('update:modelValue', option.getAttribute('value') as string)
}

const addValue = (event: MouseEvent | KeyboardEvent) => {
  if (!isOpen) return

  const value = searchModel.value.trim()
  if (isReference) {
    emit('insert', value)
  } else {
    manualValues.value.push(value)
    emit('update:modelValue', value)
  }

  clearValue(event)

  const select = (event.target as HTMLElement).closest('avv-select')
  if (select) {
    window.avv_select_close(select as HTMLSelectElement)
  }
}

const clearValue = (event: MouseEvent | KeyboardEvent) => {
  event.preventDefault()
  event.stopPropagation()

  searchModel.value = ''
}

const editValue = (value: string) => {
  const [cellValue, cellId] = dsSplit(value)
  emit('edit', cellValue, cellId)
}

const displayValue = (value: string) => {
  if (isReference) {
    return dsCellName(value)
  } else {
    return value
  }
}
</script>
<style lang="scss" scoped>
input[type='search'].no-search-decoration {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
</style>
